import request from '@/utils/Request'

// 证书模板列表
export function getCertificateTemplateList(params) {
  return request({
    url: `/certificate/admin/templateContent/index`,
    method: 'get',
    params
  })
}

// 证书模板详情
export function getCertificateTemplateDetail(params) {
  return request({
    url: `/certificate/admin/templateContent/${ params.id }`,
    method: 'get',
    params
  })
}

// 证书模板保存
export function saveCertificateTemplateDetail(data) {
  return request({
    url: `/certificate/admin/templateContent/save`,
    method: 'post',
    data
  })
}

// 证书模板内容预览
export function getPreviewTemplateImage(data) {
  return request({
    url: `/certificate/admin/templateContent/getPreview`,
    method: 'post',
    data
  })
}

// 证书模板删除
export function deleteCertificateTemplate(id) {
  return request({
    url: `/certificate/admin/templateContent/${ id }`,
    method: 'delete'
  })
}

// 证书新基础模板
export function getTemplateTypeList() {
  return request({
    url: `/public/conf/cert/templateNew`,
    method: 'get'
  })
}
