<script>

import { getPreviewTemplateImage } from '../api'

import ImageTemplate01 from './template01.png'
import ImageTemplate02 from './template02.png'
import ImageTemplate03 from './template03.png'


export default {
  name: 'Preview',
  props: {
    value: { type: [String, Number], default: '' },
    data: { type: Object, default: () => Object.assign({}) }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    placeholderImage() {
      return {
        4: ImageTemplate01,
        5: ImageTemplate02,
        6: ImageTemplate03
      }[this.data.certificate_template_id]
    },
    modified() {
      return Object.keys(this.data || {}).some((key) => this.data[key] !== this.copyData[key])
    }
  },
  data() {
    return {
      copyData: {...(this.data || {})},

      loading: false,
      shown: false,

      url: ''
    }
  },
  methods: {
    async preview(formState) {
      if(!this.modified && this.value) {
        return Promise.resolve({
          data: {
            preview_pic_id: this.value || '',
            preview_pic_id_str: this.url
          }
        })
      }

      const params = {
        title: '',
        certificate_template_id: '',
        training_content: '',
        sig_pic_id: '',
        chapter_pic_id: '',
        company_name_pic_id: ''
      }

      Object.keys(params).forEach((key) => params[key] = formState[key] || '')
      params.id = this.data.id || '';

      return await getPreviewTemplateImage(params)
    },
    async onPreview() {
      this.onShow()

      this.loading = true

      const data = await this.preview(this.data)

      this.url = data?.data?.preview_pic_id_str || ''

      this.loading = false

      this.$emit('change', data?.data?.preview_pic_id || '')

      this.copyData = { ...(JSON.parse(JSON.stringify(this.data))), file_resource_id: data?.data?.preview_pic_id || '' }
      return data?.data?.preview_pic_id || ''
    },
    onShow() {
      this.shown = true
    },
    onClose() {
      this.shown = false
    }
  }
}
</script>

<template>
  <div>
    <img :src="placeholderImage" alt="" class="placeholder-image" @click="onPreview">

    <a-modal v-model="shown"
             :confirm-loading="loading"
             :mask-closable="true"
             width="85vw"
             :centered="true"
             :footer="null">
      <a-spin v-if="loading" tip="加载中..." class="loading"></a-spin>
      <img v-else :src="url" alt="" class="img">
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.placeholder-image {
  display: block;
  width: 192px;
  height: 192px;
  border: none;
  object-fit: contain;
  cursor: pointer;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 65vh;
}

.img {
  display: block;
  width: 80vw;
  height: 65vh;
  object-fit: contain;
}
</style>
