<template>
  <SelectBase v-model="ids"
              :list="data"
              :options="{id: 'id', name: 'title'}"
              :allow-clear="false"
              :disabled="disabled"
              mode="default"
              placeholder="请选择类型"
              @change="onChange"/>
</template>

<script>
import {
  getCertificateCodeCateList,
} from '../api';
import SelectBase from '@/components/select/SelectBase.vue';

export default {
  name: 'SelectCode',
  components: {
    SelectBase,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    hiddenNames: { type: String, default: '' },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.ids = val || '';

        this.onChanged();
      },
    },
  },
  data() {
    return {
      ids: '',
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const hidden = this.hiddenNames.split(',');
      const data = await getCertificateCodeCateList();
      this.data = (data?.data || []).filter((i) => !hidden.includes(i.en));

      this.onChanged();
    },
    onChange() {
      this.$emit('change', this.ids);
      this.onChanged();
    },
    onChanged() {
      this.$emit('changed', this.data.filter((i) => {
        return this.ids.split(',').map((id) => +id).includes(+i.id);
      }));
    },
  },
};
</script>

<style scoped lang="scss">

</style>
