<script>

import UploadImage from '@components/upload/UploadImage.vue'
import Preview from './components/Preview.vue'

import { getTemplateTypeList, getCertificateTemplateDetail, saveCertificateTemplateDetail } from './api'




export default {
  name: 'Detail',
  components: { Preview, UploadImage },
  computed: {
    rules() {
      return {
        title: [{ required: true, message: '请输入模板名称' }],
        certificate_template_id: [{ required: true, message: '请选择模板类型' }],
        training_content: [{ required: true, message: '请填写培训内容' }],

        sig_pic_id: [{ required: true, message: '请上传证书签名图片' }],
        company_name_pic_id: [{ required: true, message: '请上传发证公司名称图片' }],
        chapter_pic_id: [{ required: true, message: '请上传证书章图片' }]
      }
    }
  },
  data() {
    return {
      uploading: {
        sign: false,
        company: false,
        seal: false
      },
      loading: false,

      templateTypeList: [],

      certificateSignList: [],
      certificateCompanyList: [],
      certificateSealList: [],
      formState: {
        id: this.$route.query.id || '',
        title: '',
        certificate_template_id: undefined, // 模板ID
        training_content: '', // 培训内容
        sig_pic_id: undefined, // 签名图片ID
        company_name_pic_id: undefined, // 公司名称图片
        chapter_pic_id: undefined, // 证书章图片ID

        preview_pic_id: undefined // 预览图片ID
      }
    }
  },
  created() {
    this.getTemplateTypeList()
    this.getDetail()
  },
  methods: {
    async getTemplateTypeList() {
      const data = await getTemplateTypeList()
      this.templateTypeList = data?.data || []
    },

    async getDetail() {
      if(this.loading || !this.$route.query.id) {
        return
      }
      this.loading = true

      const params = { id: this.$route.query.id || '' }
      const data = await getCertificateTemplateDetail(params).finally(() => {
        this.loading = false
      })

      if(!data || data.error_code) {
        return
      }

      const result = data.data || {}
      Object.keys(this.formState).forEach(key => {
        this.formState[key] = result[key]
      })

      const temp = {
        uid: -1,
        name: 'xxx.png',
        response: {
          status: 'success'
        },
        status: 'done'
      }
      this.certificateSignList = [{ ...JSON.parse(JSON.stringify(temp)), url: result.sig_pic_id_str }]
      this.certificateCompanyList = [{ ...JSON.parse(JSON.stringify(temp)), url: result.company_name_pic_id_str }]
      this.certificateSealList = [{ ...JSON.parse(JSON.stringify(temp)), url: result.chapter_pic_id_str }]
    },

    onCertificateSignChange(evt) {
      this.formState.sig_pic_id = evt?.[0]?.response?.data?.file_resource_id || ''
    },

    onCertificateCompanyChange(evt) {
      this.formState.company_name_pic_id = evt?.[0]?.response?.data?.file_resource_id || ''
    },

    onCertificateSealChange(evt) {
      this.formState.chapter_pic_id = evt?.[0]?.response?.data?.file_resource_id || ''
    },

    onUploading(value, field) {
      this.uploading[field] = !!value
      this.loading = Object.keys(this.uploading).some(key => this.uploading[key])
    },

    async onSubmit() {
      this.$refs.ruleForm.validate((isValid, errors) => {
        if(isValid) {
          this.onSave()
        } else {
          const messageList = []
          Object.keys(errors || {}).forEach((key) => errors[key].map(i => messageList.push(i.message)))
          this.$message.error(messageList.join('、'))
        }
      })
    },
    async onSave() {
      if(this.loading) {
        return
      }
      this.loading = true

      const params = { ...this.formState }

      if(this.$route.query.id) {
        params.id = this.$route.query.id
      }

      const data = await saveCertificateTemplateDetail(params).catch(e => e)

      this.loading = false

      if(data?.error_code) {
        this.$message.error(data?.message || '保存失败')
        return
      }
      this.$message.success(data?.message || '保存成功')
      this.$router.back()
    }
  }
}
</script>

<template>
  <a-form-model ref="ruleForm"
                :model="formState"
                :rules="rules"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 20 }"
                :disabled="loading"
                name="certificate_template_create"
                layout="horizontal"
                autocomplete="off"
                class="form">

    <a-form-model-item label="模板名称" name="title" prop="title">
      <a-input v-model="formState.title" placeholder="请输入模板名称" />
    </a-form-model-item>

    <a-form-model-item label="模板类型" name="certificate_template_id" prop="certificate_template_id">
      <a-radio-group v-model="formState.certificate_template_id">
        <a-radio v-for="item in templateTypeList" :key="item.id" :value="item.id" class="mt-10 mb-10">
          {{ item.title }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="培训内容" name="training_content" prop="training_content">
      <a-input v-model="formState.training_content" :max-length="20" no-resize placeholder="请输入培训内容" />
    </a-form-model-item>

    <a-form-model-item label="证书签名" name="sig_pic_id" prop="sig_pic_id">
      <UploadImage v-model="certificateSignList"
                   :width-recommendation="152"
                   :height-recommendation="152"
                   @change="onCertificateSignChange"
                   @loading="onUploading($event, 'sign')" />
    </a-form-model-item>

    <a-form-model-item label="发证公司" name="company_name_pic_id" prop="company_name_pic_id">
      <UploadImage v-model="certificateCompanyList"
                   :width-recommendation="316"
                   :height-recommendation="84"
                   @change="onCertificateCompanyChange"
                   @loading="onUploading($event, 'company')" />
    </a-form-model-item>

    <a-form-model-item label="证书章" name="chapter_pic_id" prop="chapter_pic_id">
      <UploadImage v-model="certificateSealList"
                   :width-recommendation="220"
                   :height-recommendation="220"
                   @change="onCertificateSealChange"
                   @loading="onUploading($event, 'seal')" />
    </a-form-model-item>

    <a-form-model-item label="证书预览">
      <Preview ref="previewRef" v-model="formState.preview_pic_id" :data="formState" />
    </a-form-model-item>

    <a-form-model-item label=" " :colon="false">
      <a-button :loading="loading" class="save-button" @click="onSubmit">保存</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<style scoped lang="scss">
.form {
  width: 750px;
  margin: 0 auto;
}
</style>
