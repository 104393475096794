<template>
  <div class="loading">
    <div class="laoding-spin">
      <div class="laoding-mask"></div>
      <a-spin class="loading-icon" :spinning="spinning"> </a-spin>
    </div>
  </div>
</template>

<script>
export default {};
</script>



<style scoped lang="scss">
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  .laoding-spin {
    position: relative;
    width: 100%;
    height: 100%;
    top: 80px;
    left: 0;
  }
  .laoding-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    opacity: 0.7;

  }
  .loading-icon {
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
  }
}
</style>