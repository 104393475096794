import request from '@/utils/Request';

// 商品列表
export function goodsList(params) {
  return request({
    url: `/shop/admin/integralConsumption/goodsList`,
    method: 'get',
    params,
  });
}


// 商品列表
export function integralConsumptionIndex(params) {
  return request({
    url: `/shop/admin/integralConsumption/index`,
    method: 'get',
    params,
  });
}