<template>
  <div class="deplete-configuration">
    <depleteTitle :title="title"></depleteTitle>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item
        v-for="(item, index) in data"
        :key="index"
        :label="item.goods_name"
      >
        <a-input-number v-model="item.integral" />
        <span class="ml-20">
          <a-button type="primary" @click="saveChange(item)"> 保存 </a-button>
        </span>
        <span class="ml-20 deplete-tip">
          <a-icon type="warning" theme="filled" />
          必须为数字，0表示免费用
        </span>
      </a-form-model-item>
      <!-- <a-form-model-item label="补考卡">
        <a-input-number :min="0" v-model="form.integral" @change="onChange" />
        <span class="ml-20">
          <a-button type="primary"> 保存 </a-button>
        </span>
        <span class="ml-20 deplete-tip">
          <a-icon type="warning" theme="filled" />
          必须为数字，0表示使用功能不限制
        </span>
      </a-form-model-item> -->
    </a-form-model>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import depleteTitle from './components/title.vue';
import {
  updConsumptionConf,
  consumptionConfIndex
} from '../credit-configuration/index';
import Loading from '@/components/Loading.vue';
export default {
  name: 'deplete-configuration',
  components: {
    depleteTitle,
    Loading
  },
  data() {
    return {
      title: '消耗配置',
      labelCol: { span: 1 },
      wrapperCol: { span: 14 },
      form: {
        integral: 0,
        flag: 0
      },
      data: [],
      loading: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async getData() {
      this.loading = true;
      const data = await consumptionConfIndex().finally(() => {
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.data = data.data;
    },
    async updConsumptionConf(params) {
      this.loading = true;
      const data = await updConsumptionConf({
        flag: params.flag,
        integral: params.integral
      }).finally(() => {
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        this.$message.error(`修改失败!${data.message}`);
        return;
      }
      this.$message.success('修改成功');
    },
    init() {
      this.getData();
    },
    saveChange(data) {
      if (data.integral < 0) {
        this.$message.error(`${data.goods_name}不能小于0`);
        data.integral = 0;
        return;
      }
      this.updConsumptionConf(data);
    }
  }
};
</script>

<style  lang="scss" scoped>
.deplete-configuration {
  padding: 20px;
  position: relative;
  .deplete-tip {
    color: #d9001b;
    font-size: 16px;
  }
}
.ml-20 {
  margin-left: 20px;
}
</style>