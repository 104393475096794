<template>
  <div>
    <div class="flex space-between flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">导入日期：</span>
        <a-range-picker v-model="dates"
                        :getCalendarContainer="(triggerNode) => triggerNode.parentNode"/>
      </div>
      <div class="flex mb-10">
        <a-button type="primary" @click="onSearch()">查询</a-button>
        <a-button class="mr-30" @click="onReset">重置</a-button>
        <a-button type="primary" @click="onImport">导入数据表</a-button>
      </div>
    </div>

    <div class="ph-20">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id">
        <span slot="action" slot-scope="text, record">
          <a-button v-if="record.status === 2"
                    type="link"
                    @click="downloadExcel(record)">
            下载导入数据表
          </a-button>
          <a-button v-if="record.status === 10 && record.error_file_url"
                    type="link"
                    @click="downloadErrorExcel(record)">
            查看错误信息
          </a-button>
        </span>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <ImportCertificateModal ref="importCertificateModalRef"
                            @success="onSearch"/>
  </div>
</template>

<script>
import moment from 'moment';

import {
  getImportRecords,
} from './api';

import Pagination, {
  getPagination,
  updatePagination,
} from '../../../components/table/Pagination.vue';
import ImportCertificateModal from './components/ImportCertificateModal.vue';

const columns = [
  {
    width: '140px',
    align: 'center',
    dataIndex: 'created_at',
    key: 'created_at',
    title: '导入日期',
    customRender: (text) => moment(text * 1000).format('YYYY-MM-DD HH:mm'),
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'created_user_name',
    key: 'created_user_name',
    title: '操作人',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'status_name',
    key: 'status_name',
    title: '导入状态',
  },
  {
    width: '140px',
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
    ImportCertificateModal,
  },
  data() {
    return {
      loading: false,
      dates: [undefined, undefined],
      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onReset() {
      this.dates = [undefined, undefined];

      this.onSearch();
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;

      const params = {
        page,
        pre_page: pageSize,
        start_time: this.dates?.[0]?.format('YYYY-MM-DD') || '',
        end_time: this.dates?.[1]?.format('YYYY-MM-DD') || '',
      };
      const data = await getImportRecords(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || data?.info || [];
      this.pagination = updatePagination(this.pagination, data?.meta || {});
    },

    onImport() {
      if (this.$refs.importCertificateModalRef?.show) {
        this.$refs.importCertificateModalRef.show();
      }
    },

    downloadExcel(record) {
      if (!record.file_url) {
        this.$message.info('未找到文件地址');
        return;
      }
      window.open(record.file_url, '_blank');
    },
    downloadErrorExcel(record) {
      if (!record.error_file_url) {
        this.$message.info('未找到错误文件地址');
        return;
      }
      window.open(record.error_file_url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
